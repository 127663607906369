

/**
 * 新大作文评分
 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { IGetWritingTask2EvaluationDraft_Response } from '@/api/writing/evaluation';
import { getPageListByModule } from '@/api/writing/topicList2';

export interface IEvaluationInfo {
  content: string;
  update_time: number;
  paper_title: string;
}

export interface IEvaluationState {
  paperId: number;
  recordId: number;
  info: IEvaluationInfo;
  /** 状态：1未完成 2已完成 3已评分 */
  status: number; 
  /** 临时凭证, 用于给移动端进行图片上传 */
  securityToken: string;
  loaded: boolean;
  feedback: boolean;
  subject: string;
}

export const initialState: IEvaluationState = {
  paperId: 0,
  recordId: 0,
  info: {
    content: '',
    update_time: 0,
    paper_title: ''
  },
  status: 1,
  securityToken: '',
  loaded: false,
  feedback: false,
  subject: ''
};

/**
 * 检测输入框的内容是否和库中的题目内容相同
 */
export const checkSubjectIsSameAsTopic = createAsyncThunk('evaluationSlice/checkSubjectIsSameAsTopic', async (params: string) => {
  const res = await getPageListByModule({
    'page': 1,
    'page_size': 10,
    'module_id': 6,
    'year': 0,
    'category': 0,
    'topic': 0,
    'content': params,
    'subject_type': 0,
    'is_accurate': 1
  });
  if (res.code === 200 && res.data.list.length >= 1) {
    const topic = res.data.list[0];
    return {
      id: topic.id
    };
  } else {
    return {id: 0};
  }
});

export const evaluationSlice = createSlice({
  name: 'evaluation',
  initialState,
  reducers: {
    setEvaluationInfo: (state, action: { payload: IGetWritingTask2EvaluationDraft_Response; }) => {
      state.paperId = action.payload.paper_id;
      state.recordId = action.payload.record_id;
      state.info = {
        content: action.payload.content,
        update_time: action.payload.update_time,
        paper_title: action.payload.paper_title
      };
      state.status = action.payload.status;
      state.loaded = true;
      state.feedback = action.payload.status !== 1;
      state.subject = action.payload.subject;
    },
    resetEvaluationInfo: (state)=>{
      state.paperId = 0;
      state.recordId = 0;
      state.info = {
        content: '',
        update_time: 0,
        paper_title: ''
      };
      state.status = 1;
      state.loaded = false;
      state.feedback = false;
      state.subject = '';
    },
    setPaperId: (state, action: {payload: number;})=>{
      state.paperId = action.payload;
    },
    setEvaluationContent: (state, action: { payload: string; }) => {
      state.info.content = action.payload;
    },
    setEvaluationSubject: (state, action: { payload: string; }) => {
      state.subject = action.payload;
    },
    setSecurityToken: (state, action: { payload: string; }) => {
      state.securityToken = action.payload;
    },
    setFeedback: (state) => {
      state.feedback = true;
    },
  },
  extraReducers(builder) {
    builder.addCase(checkSubjectIsSameAsTopic.fulfilled, (state, action)=>{
      state.paperId = action.payload.id;
    });
  },
});

export const EvaluationAction = evaluationSlice.actions;
