import { post } from '@/utils/network/axios-helper';

export interface SaveAiLikeRequest {
    /**
     * ai回复记录id
     */
    ai_record_id?: number;
    /**
     * 业务记录id
     */
    business_record_id?: number;
    /**
     * 1大作文批改 2小作文批改 3写作基础练习 4阅读基础练习 5听力基础练习 6审题练习 7思路拓展 8分段练习 9整篇练习 10强化练习-阅读 11强化练习-听力
     * 12模拟考试-听力 12模拟考试-阅读 13模拟考试-写作 14全局
     */
    business_type: number;
    /**
     * 追问记录id
     */
    pre_id?: number;
    /**
     * 纠错记录id
     */
    problem_id?: number;
    /**
     * 类型：-1无 1赞 2踩
     */
    type: number;
}

export const saveAiLikeStep = (param: SaveAiLikeRequest) => {
  return post<{id:number;}>('/api/student/save-ai-like-step', param);
};


export interface GetAiLikeStepListRequest {
  /**
   * ai回复记录id
   */
  ai_record_id: number[];
  /**
   * 业务记录id
   */
  business_record_id: number[];
  /**
   * 1大作文批改 2小作文批改 3写作基础练习 4阅读基础练习 5听力基础练习 6审题练习 7思路拓展 8分段练习 9整篇练习 10强化练习-阅读 11强化练习-听力
   * 12模拟考试-听力 12模拟考试-阅读 13模拟考试-写作 14全局
   */
  business_type: number;
  /**
   * 追问记录id
   */
  pre_id?: number[];
  /**
   * 纠错记录id
   */
  problem_id?: number[];
  [property: string]: any;
}

export interface GetAiLikeStepListResponse {
  list:Array<{id: number;
  type: number;}>;
}

export const getAiLikeStepList = (param: GetAiLikeStepListRequest) => {
  return post<GetAiLikeStepListResponse>('/api/student/get-ai-like-step-list', param);
};