import { createSlice } from '@reduxjs/toolkit';
import { IWritingTask1Evaluation, IWritingTask2Evaluation, IWritingTask2Practice, IWritingTask2SampleEssay, IMockTask2Practice, IReadingRecordItem, ITrainingRecordItem} from '@/api/history';
import { IExchangeItem, IGetExchangeHistory_Res } from '@/api/entitlements';

export interface IListInfo<T extends { id: number; }> {
  list: T[];
  total: number;
  currentPage: number;
  pageSize: number;
  search_content: string;
  [key: string]: any;
}

export type IListInfoItemType<T extends IListInfo<any>> = ArrayElementType<T['list']>;

export interface IHistoryState {
  listInfo: {
      // 练习记录-写作
    writingPracticeTask2List: IListInfo<IWritingTask2Practice>;
    // 小作文评分
    writingEvaluationTask1List: IListInfo<IWritingTask1Evaluation>;
    // 大作文评分
    writingEvaluationTask2List: IListInfo<IWritingTask2Evaluation>;
    // 范文
    writingSampleEssayTask2List: IListInfo<IWritingTask2SampleEssay>;
    //模考
    mockTestTaskList: IListInfo<IMockTask2Practice>;
    // 阅读
    readingRecordList: IListInfo<IReadingRecordItem>;
    // 听力
    listenigRecordList: IListInfo<IReadingRecordItem>;
    // 基础练习
    trainingWRecordList: IListInfo<ITrainingRecordItem>;
    trainingRRecordList: IListInfo<ITrainingRecordItem>;
    trainingLRecordList: IListInfo<ITrainingRecordItem>;

    //exchange
    exchangeRecordList: IListInfo<IExchangeItem> & Pick<IGetExchangeHistory_Res, 'exchange_result'>;
  };
  reload: number;
}

const initialState: IHistoryState = {
  listInfo: {
    writingPracticeTask2List: { list: [], total: -1, currentPage: 1, pageSize: 10, search_content: '' },
    writingEvaluationTask1List: { list: [], total: -1, currentPage: 1, pageSize: 10, search_content: '' },
    writingEvaluationTask2List: { list: [], total: -1, currentPage: 1, pageSize: 10, search_content: '' },
    writingSampleEssayTask2List: { list: [], total: -1, currentPage: 1, pageSize: 10, search_content: '' },
    mockTestTaskList: { list: [], total: -1, currentPage: 1, pageSize: 10, search_content: '' },
    readingRecordList: { list: [], total: -1, currentPage: 1, pageSize: 10, search_content: '' },
    listenigRecordList: { list: [], total: -1, currentPage: 1, pageSize: 10, search_content: '' },
    trainingWRecordList: { list: [], total: -1, currentPage: 1, pageSize: 10, search_content: '' },
    trainingRRecordList: { list: [], total: -1, currentPage: 1, pageSize: 10, search_content: '' },
    trainingLRecordList: { list: [], total: -1, currentPage: 1, pageSize: 10, search_content: '' },
    exchangeRecordList: { list: [], total: -1, currentPage: 1, pageSize: 10, search_content: '', exchange_result: { task_correct_num: 0, model_essay_num: 0 } },
  },
  reload: 0
};

export const historyListSlice = createSlice({
  name: 'history-list',
  initialState,
  reducers: {
    setListInfo: (state, action: { payload: { key: keyof IHistoryState['listInfo']; value: IListInfo<any> & { [key: string]: any; }; appendKeys?: string[];}; }) => {
      // 当前需要修改的list如下
      // const listInfo = state.listInfo[action.payload.key];

      if (!state.listInfo[action.payload.key]) {
        return;
      }
      
      if (state.listInfo[action.payload.key].total <= 0 || state.listInfo[action.payload.key].currentPage === 1) {
        state.listInfo[action.payload.key].list = action.payload.value.list;
        state.listInfo[action.payload.key].total = action.payload.value.total;
        state.listInfo[action.payload.key].currentPage = 2;
      } else {
        state.listInfo[action.payload.key].currentPage = state.listInfo[action.payload.key].currentPage + 1;
        // const compare = difference(action.payload.value.list.map((ele) => ele.id), state.listInfo[action.payload.key].list.map((ele) => ele.id));
        // console.log(compare.length);
        // if (compare.length <= 0) {
        //   return;
        // }
        // state.listInfo[action.payload.key].list = [...state.listInfo[action.payload.key].list, ...action.payload.value.list.filter((ele) => compare.find((id) => ele.id === id))];
        state.listInfo[action.payload.key].list.push(...action.payload.value.list);
      }
      if (action.payload.appendKeys && action.payload.appendKeys.length > 0) {
        action.payload.appendKeys.forEach((k) => {
          if (action.payload.value[k]) {
            state.listInfo[action.payload.key][k] = action.payload.value[k];
          }
        });

      }
    },
    setSeachContent: (state, action: {payload: {key: keyof IHistoryState['listInfo']; content: string;};}) => {
      state.listInfo[action.payload.key].search_content = action.payload.content;
      state.listInfo[action.payload.key].currentPage = 1;
      state.listInfo[action.payload.key].total = 0;
    },
    reload: (state, action: {payload: {key: keyof IHistoryState['listInfo'];};}) => {
      state.listInfo[action.payload.key].currentPage = 1;
      state.listInfo[action.payload.key].total = 0;
      state.listInfo[action.payload.key].list = [];
      if (action.payload.key === 'exchangeRecordList') {
        state.listInfo[action.payload.key].exchange_result = {
          task_correct_num: 0,
          model_essay_num: 0,
        };
      }
      state.reload = Math.random();
    },
    clear: (state, action: {payload: {key: keyof IHistoryState['listInfo'];};}) => {
      state.listInfo[action.payload.key].currentPage = 1;
      state.listInfo[action.payload.key].total = 0;
      state.listInfo[action.payload.key].list = [];
    },
  },
});

export const HistoryListSliceAction = historyListSlice.actions;
