import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  IBasicConfigResponse,
  IBasicQuestion,
  IConfigItem,
  IItemSelected,
  getQuestionList,
  getSearchConfig,
} from '@/api/basic/list';

interface ITrianingListState {
  fetchingConfig: boolean;
  fetchingList: boolean;
  doFetchConfig: number;
  doFetchList: number;
  type: number; // 1写作 2阅读 3 听力
  selected: IItemSelected[];
  config: IConfigItem[];

  list: IBasicQuestion[];
  fields: string[];
  page: number;
  page_size: number;
  total: number;
  reloadState: number;
  fields_name_map: any;
  selectedKey: string;
}
const initialState: ITrianingListState = {
  fetchingConfig: false,
  fetchingList: false,
  doFetchConfig: 0,
  doFetchList: 0,
  type: 0,
  selected: [],
  config: [],

  list: [],
  fields: [],
  page: 1,
  page_size: 10,
  total: 0,
  reloadState: 0,
  fields_name_map: {},
  selectedKey: ''
};

const configs = new Map<string, IBasicConfigResponse>();

// 获取筛选条件列表
export const getSearchConfigAsync = createAsyncThunk(
  'basicTraingList/getSearchConfig',
  async (_, { rejectWithValue, getState }) => {
    try {
      const state = (getState() as any).basicTraingList as ITrianingListState;
      const s = state.selected;
      const k = state.type + JSON.stringify(s);
      if (configs.has(k)) {
        return configs.get(k);
      }

      const res = await getSearchConfig({ type: state.type, selected: s });
      if (res.code !== 200) {
        rejectWithValue(null);
        return;
      }
      configs.set(k, res.data);
      return res.data;
    } catch (e) {
      rejectWithValue(null);
    }
  }
);

export const getQuestionListAsync = createAsyncThunk(
  'basicTraingList/getQuestionList',
  async (_, { rejectWithValue, getState }) => {
    try {
      const state = (getState() as any).basicTraingList as ITrianingListState;
      const keys: { [property: string]: any; } = {};
      state.selected.forEach((item) => {
        keys[item.key] = item.value;
      });
      const res = await getQuestionList({
        ...keys,
        page: state.page,
        page_size: state.page_size,
        course: state.type,
      });
      if (res.code !== 200) {
        rejectWithValue(null);
        return;
      }
      return res.data;
    } catch (e) {
      rejectWithValue(null);
    }
  }
);

export const basicTraingList = createSlice({
  name: 'basicTraingList',
  initialState,
  reducers: {
    setType: (state, action: { payload: number; }) => {
      state.type = action.payload;
      state.selected = [];
      state.config = [];
      state.page = 1;
      state.total = 0;
      state.list = [];
    },
    setSelected: (
      state,
      action: { payload: { key: string; value: number; }; }
    ) => {
      const v = state.selected.find((v) => v.key === action.payload.key);
      if (v) {
        v.value = action.payload.value;
      }
      state.selectedKey = action.payload.key;
      state.doFetchConfig = Math.random();
      state.list = [];
      state.page = 1;
      state.total = 0;
    },
    clearSelected: (state) => {
      state.selected = [];
      state.page = 1;
      state.total = 0;
    },
    getNextPage: (state) => {
      state.page = state.page + 1;
    },
    setPage: (state, action: { payload: number; }) => {
      state.page = action.payload;
    },
    setPageSize: (state, action: { payload: number; }) => {
      state.page_size = action.payload;
    },
    reload: (state) => {
      state.reloadState = Math.random();
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getSearchConfigAsync.pending, (state) => {
        state.fetchingConfig = true;
      })
      .addCase(getSearchConfigAsync.fulfilled, (state, action) => {
        if (action.payload) {
          state.config = action.payload.list;
          const items: IItemSelected[] = [];
          if (action.payload?.list) {
            action.payload.list.forEach((item) => {
              items.push({ key: item.key, value: item.list[0].value });
            });
          }
          // let changed = false;
          // items.forEach((item) => {
          //   const v = state.selected.find((v) => v.key === item.key);
          //   if (!v) {
          //     changed = true;
          //   } else {
          //     const config = state.config.find((c) => c.key === v.key);
          //     if (config) {
          //       const child = config.list.find((c) => c.value === v.value);
          //       if (!child) {
          //         v.value = config.list[0].value;
          //       } else {
          //         item.value = child.value;
          //       }
          //     }
          //   }
          // });
          // if (changed) {
          //   state.selected = items;
          // } else {
          //   state.selected = state.selected.filter((item) =>
          //     items.find((i) => i.key === item.key)
          //   );
          // }

          for (let i = 0; i < items.length; i++) {
            const selected = state.selected[i];
            if (!selected) {
              break;
            }
            const item = items[i];
            const list = action.payload.list[i].list;
            const contain = list.find((it) => it.value === selected.value);
            if (item.key === selected.key && contain) {
              item.value = selected.value;
              if (item.key === state.selectedKey && i === 0){
                break;
              }
            } else {
              break;
            }
          }
          state.selected = items;
        }
        state.fetchingConfig = false;
        state.selectedKey = '';

        state.doFetchList = Math.random();
      })
      .addCase(getSearchConfigAsync.rejected, (state) => {
        state.fetchingConfig = false;
      })

      .addCase(getQuestionListAsync.pending, (state) => {
        state.fetchingList = true;
      })
      .addCase(getQuestionListAsync.fulfilled, (state, action) => {
        if (action.payload) {
          state.list = action.payload.list;
          state.fields = action.payload.fields;
          state.total = action.payload.total;
          state.fields_name_map = action.payload.fields_name_map;
        }
        state.fetchingList = false;
      })
      .addCase(getQuestionListAsync.rejected, (state) => {
        state.fetchingList = false;
      });
  },
});
export const TrainingListActions = basicTraingList.actions;
