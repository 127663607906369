import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IGetSearchConfig_Response, IReadingPaperBook, IReadingPaperSection, IReadingPaperWithRecordInfo, getReadingPaperList, getReadingPaperListJiJing, getSearchConfig, getSearchReadingPaperList } from '@/api/read/list';

type IMethod = number; // 练习方式 1套题 2题型 3话题

export interface ISearchQuery {
  paper_ids?: number[];
  /**
   * 1剑雅 2机经 3预测
   */
  pid: number;
  search_content: string;
  topic: number;
  type?: number;
}
export interface ITopicListState {
  // local data
  fechtingSearch: boolean; ///
  fechtingList: boolean;
  pid: number; // 1剑雅 2机经 3预测
  method: IMethod;
  type: number; 
  search_content: string;
  // remote data
  searchConfig: IGetSearchConfig_Response;

  jianyaList: {
    total: number;
    page: number;
    list: IReadingPaperBook[];
  };

  jijingList: {
    total: number;
    page: number;
    list: IReadingPaperSection[];
  };

  searchList: {
    total: number;
    page: number;
    list: IReadingPaperWithRecordInfo[];
  };

  reloadState?: number;
}

const initialState: ITopicListState = {
  fechtingList: false,
  fechtingSearch: false,
  searchConfig: { ji_jing: [], jian_ya: [], yu_ce: [], },

  pid: 1, 
  method: 1,
  type: 0,
  search_content: '',

  jianyaList: {
    total: 0,
    page: 1,
    list: []
  },

  jijingList: {
    total: 0,
    page: 1,
    list: []
  },
  searchList: {
    total: 0,
    page: 1,
    list: []
  },
};

// 获取筛选条件列表
export const getSearchListAsync = createAsyncThunk('readingList/getSearchList', async (_, {
  rejectWithValue,
}) => {
  try {
    const res = await getSearchConfig();
    if (res.code !== 200) {
      rejectWithValue(null);
      return;
    }
    return res.data;
  } catch (e) {
    rejectWithValue(null);
  }

});

/**
 * 获取剑雅 套题 列表
 */
export const getJianyaReadingList = createAsyncThunk('readingList/getJianyaReadingList', async (_, {
  rejectWithValue, getState,
}) => {
  try {
    const state = (getState() as any).readingList as ITopicListState;
    const res = await getReadingPaperList({
      type: state.type,
      page: state.jianyaList.page,
      page_size: 2
    });
    if (res.code !== 200) {
      rejectWithValue(null);
      return;
    }

    return res.data;
  } catch (e) {
    rejectWithValue(null);
  }
});
/**
 * 获取机经 套题 列表
 */
export const getJijingReadingList = createAsyncThunk('readingList/getJijingReadingList', async (_, {
  rejectWithValue, getState,
}) => {
  try {
    const state = ((getState() as any).readingList) as ITopicListState;
    const res = await getReadingPaperListJiJing({ unit: state.type, page: state.jijingList.page, page_size: 6 });
    if (res.code !== 200) {
      rejectWithValue(null);
      return;
    }

    return res.data;
  } catch (e) {
    rejectWithValue(null);
  }
});

/**
 * 根据条件获取题型或话题的列表
 */
export const getSearchReadingList = createAsyncThunk('readingList/getSearchReadingList', async (_, {
  rejectWithValue, getState,
}) => {
  try {
    const state = (getState() as any).readingList as ITopicListState;
    const params = {
      pid: state.pid,
      search_content: state.search_content,
      topic: 0,
      type: 0,
      page: state.searchList.page,
      page_size: 10
    };
    if (state.method === 2){
      params.type = state.type;
    } else if (state.method === 3){
      params.topic = state.type;
    }
    const res = await getSearchReadingPaperList(params);
    if (res.code !== 200) {
      rejectWithValue(null);
      return { total: 0, list: [] };
    }
    return res.data;
  } catch (e) {
    rejectWithValue(null);
    return {total: 0, list: []};
  }
});

export const readingListSlice = createSlice({
  name: 'readingList',
  initialState,
  reducers: {
    setPid: (state, action: {payload: number;})=>{
      state.pid = action.payload;
      state.type = 1;
      state.method = 1;
      if (action.payload === 1){
        state.jianyaList.page = 1;
        state.type = 0;
      } else if (action.payload === 2){
        state.jijingList.page = 1;
        state.type = 0;
      } else if (action.payload === 3){
        state.searchList.page = 1;
        state.type = 1;
      }
    },
    
    setSearchMethod: (state, action: { payload: {method: IMethod; type: number;}; }) => {
      state.method = action.payload.method;
      state.type = action.payload.type;
      state.jianyaList.page = 1;
      state.searchList.page = 1;
      state.jijingList.page = 1;
    },
    setSearchContent: (state, action: { payload: string; }) => {
      state.search_content = action.payload;
      state.jianyaList.page = 1;
      state.searchList.page = 1;
      state.jijingList.page = 1;
    },
    setSearchType: (state, action: { payload: number; }) => {
      state.type = action.payload;
      state.jianyaList.page = 1;
      state.searchList.page = 1;
      state.jijingList.page = 1;
    },
    setReload: (state) => {
      state.reloadState = Math.random();
      state.jianyaList.page = 1;
      state.searchList.page = 1;
      state.jijingList.page = 1;
    },
    getNextPage: (state) => {
      if (state.pid === 1 && state.method === 1) {
        state.jianyaList.page += 1;
       
      } else if (state.pid === 2 && state.method === 1){
        state.jijingList.page += 1;
      } else {
        state.searchList.page += 1;
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getJianyaReadingList.pending, (state) => {
        state.fechtingList = true;
      })
      .addCase(getJianyaReadingList.fulfilled, (state, action) => {
        state.fechtingList = false;
        if (action.payload) {
          const newList = state.jianyaList.page === 1 ? action.payload.list : state.jianyaList.list.concat(action.payload.list);
          state.jianyaList.list = newList;
          state.jianyaList.total = action.payload.total;
        }
      })

      .addCase(getJijingReadingList.pending, (state) => {
        state.fechtingList = true;
      })
      .addCase(getJijingReadingList.fulfilled, (state, action) => {
        state.fechtingList = false;
        if (action.payload) {
          const newList = state.jijingList.page === 1 ? action.payload.list : state.jijingList.list.concat(action.payload.list);
          state.jijingList.list = newList;
          state.jijingList.total = action.payload.total;
        }
      })

      .addCase(getSearchReadingList.pending, (state) => {
        state.fechtingList = true;
      })
      .addCase(getSearchReadingList.fulfilled, (state, action) => {
        state.fechtingList = false;
        if (action.payload) {
          const newList = state.searchList.page === 1 ? action.payload.list : state.searchList.list.concat(action.payload.list);
          state.searchList.list = newList;
          state.searchList.total = action.payload.total;
        }
      })

      .addCase(getSearchListAsync.pending, (state) => {
        state.fechtingSearch = true;
      })
      .addCase(getSearchListAsync.fulfilled, (state, action) => {
        state.fechtingSearch = false;
        if (action.payload) {
          state.searchConfig = action.payload;
        }
      });
  },
});

export const ReadingListActions = readingListSlice.actions;

