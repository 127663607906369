import { CSSProperties } from 'react';
import { EventCenter } from '@/modules/Swift/EventCenter';

export let windowScale = 1;
const min_size = 800;
export const scaleWindow = () => {
  if (EventCenter.isPC()){
    const wh = window.screen.height;
    if (wh < min_size) {
      const ih = window.innerHeight;
      const dp = min_size / ih;
      windowScale = dp;
      (document.body.style as CSSProperties).zoom = 1 / dp;
    }
  }
  
};

export const getZoom = () => {
  try {
    const v = document.body.computedStyleMap().get('zoom')?.toString();
    if (v) {
      return parseFloat(v);
    }
    return windowScale;
  } catch (e) {
    return windowScale;
  }
};
