import Axios from 'axios';
import { AxiosResponseType } from '@/utils/network/axios-helper';
import { IGetWritingTask2ScoreInfo_Response, IGetWritingTask2ScoreStatus_Response } from './evaluation';

export interface EssayPaperData {
    content: string;
    id: number;
    img_desc: string;
    img_url: string;
    record_id: number;
    status: number;
    title: string;
    update_time: number;
}
/**
 * 获取小作文题目信息
 * @param params 
 * @returns 
 */
export const getEssayPaperData = async (params: {id: number;}): Promise<AxiosResponseType<EssayPaperData>> => {
  return Axios.post('/api/exercise/get-essay-paper-info', params);
  // return {code: 200, msg: 'OK', data: data};
};

export interface GetEssayPaperDataResponse{
  content:string;
  create_time:number;
  id:number;
  paper_content: string;
  paper_title:string;
  score:number;
  status:number;
  update_time:number;
  subject_url:string;
  subject_status:number; // 1未检测 2检测中 3可用 4不可用
  humor_reply: string;
}
/**
 * 获取小作文已写数据
 */
export const getEssayRecordData = async (params: {id: number;}): Promise<AxiosResponseType<GetEssayPaperDataResponse>> => {
  return Axios.post('/api/exercise/get-essay-record-info', params);
  // return {code: 200, msg: 'OK', data: data};
};


export interface SaveEssayRequest{
  id: number;
  paper_id:number;
  content?:string;
}
export interface SaveEssayResponse{id: number;deadline:number;code:string;}
/**
 * 保存小作文/创建写作副本
 * @param params 
 * @returns 
 */
export const saveEssayRecordData = async (params: SaveEssayRequest): Promise<AxiosResponseType<SaveEssayResponse>> => {
  return Axios.post('/api/exercise/save-essay-record-info', params);
};

export interface SaveEssaySubjectRequest{
  id: number;
  paper_id:number;
  subject_url:string;
}
export const saveEssayRecordSubject = async (params: SaveEssaySubjectRequest): Promise<AxiosResponseType<any>> => {
  return Axios.post('/api/exercise/save-essay-record-subject', params);
};

/**
 * 进行小作文评分
 * @param params 
 * @returns 
 */
export const doEssayScoring = async (params:{content: string; record_id: number; subject_url: string;}): Promise<AxiosResponseType<{record_id: number;}>> => {
  return Axios.post('/api/exercise/essay-scoring', params);
};


/**
 * 获取小作文评分结果
 * @param params 
 * @returns 
 */
export const getEssayScoringResult = async (params:{record_id: number;}): Promise<AxiosResponseType<IGetWritingTask2ScoreStatus_Response>> => {
  return Axios.post('/api/exercise/get-essay-scoring-result', params);
};


/**
 * 获取小作文评分详情
 * @param params 
 * @returns 
 */
export const getEssayScoringInfo = async (params:{record_id: number;}): Promise<AxiosResponseType<IGetWritingTask2ScoreInfo_Response>> => {
  return Axios.post('/api/exercise/get-essay-score-info', params);
};

export interface IEssayAvailableData {
    /**
     * 不可用数据提醒
     */
    humor_reply: string;
    /**
     * 1可用 2不可用
     */
    is_available: number;
}

export const getEssaySubjectAvailable = async (params:{subject_url: string; record_id: number;}): Promise<AxiosResponseType<IEssayAvailableData>> => {
  return Axios.post('/api/exercise/check-essay-subject', params);
};