import axios from 'axios';
import { AxiosResponseType, post } from '@/utils/network/axios-helper';

/**
 * 兑换
 * @param code 
 * @returns 
 */
export const postVoucher = async (code: string): Promise<AxiosResponseType<{
  type: string; num: string;
}>> => {
  return post('/api/student/use-voucher', {
    code
  });
};

/**
 * 
 */

export interface IGetEquityRemainInfoRes {
  task_correct_num: number; // 批改
  model_essay_num: number; // 范文
}

export const getEquityRemainInfo = async () => {
  return post<IGetEquityRemainInfoRes>('/api/student/get-equity-remain-info', {});
};


export interface IGetExchangeHistory_Req {
  page: number;
  page_size: number;
}

export interface IExchangeItem {
  create_time: string;
  equity_code: string;
  goods_name: string;
  id: number;
  is_delete: number;
  num: number;
  status: number;
  update_time: string;
}
export interface IGetExchangeHistory_Res {
  total: number;
  list: IExchangeItem[];
  exchange_result: {
    task_correct_num: number; // 批改
    model_essay_num: number; // 范文
  };
}

export const getExchangeHistory = (params: IGetExchangeHistory_Req) => {
  return post<IGetExchangeHistory_Res>('/api/student/get-exchange-record-list', params);
};