import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import i18n from '@/locales/init';
import { GetAiLikeStepListRequest, getAiLikeStepList } from '@/api/aiAnseerFeedback';

interface IFeedback{

}
interface AiFeedbackState {
  feedbacks:IFeedback[];
  tags:Array<{
      id: number;
      name: string;
    }>;
  selectedTag?: number;
  open?: boolean;
  content: string;
}

const initialState: AiFeedbackState = {
  feedbacks: [],
  tags: [{
    id: 0,
    name: i18n.t('答案不对'),
  }, {
    id: 1,
    name: i18n.t('解析不对'),
  }, {
    id: 2,
    name: i18n.t('没有帮助'),
  }, {
    id: 3,
    name: i18n.t('其他'),
  },
  ],
  content: ''
};

export const getAiLikeStepListInfo = createAsyncThunk('aiAnswerFeedback/getAiLikeStepListInfo', async (params: GetAiLikeStepListRequest, {
  rejectWithValue,
}) => {
  const res = await getAiLikeStepList(params);
  if (res.code !== 200) {
    rejectWithValue(null);
    return;
  }
  return {
    list: res.data.list,
  };
});

export const aiAnswerFeedbackSlice = createSlice({
  name: 'aiAnswerFeedback',
  initialState,
  reducers: {
    setSelectedTag: (state, action: { payload: number; }) => {
      if (state.selectedTag === action.payload){
        state.selectedTag = undefined;
        return;
      }
      state.selectedTag = action.payload;
    },
    setFeedbackOpen: (state, action: { payload: boolean; }) => {
      state.open = action.payload;
      state.content = '';
      state.selectedTag = undefined;
    },
    setContent: (state, action: { payload: string; }) => {
      state.content = action.payload;
    },
  }
});
export const AiAnswerFeedbackActions = aiAnswerFeedbackSlice.actions;
