import React from 'react';
import '@/utils/init';
import { HashRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { Toaster } from 'react-hot-toast';
import { setPrefix } from '@/utils/logger/arms-config';
import { withMobileFallbackHOC } from '@/modules/PageFramework/hocs/pcHOC/withMobileFallbackHOC';
import store from '@/stores';
import App from './App';

setPrefix('Home__');

const Comp = withMobileFallbackHOC(() => {
  return (
    <React.Suspense>
      <Provider store={store}>
        <HashRouter>
          <App />
          <Toaster />
        </HashRouter>
      </Provider>
    </React.Suspense>
  );
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(<Comp />);
