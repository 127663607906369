import { TAO_BAO_MALL } from '@/utils/const';
import { payUrl } from '@/utils/env';

export const pay = ()=>{
  const encoded = btoa(window.location.href);
  window.location.href = `${payUrl}?redirectUrl=${encoded}`;
};

export const toTaobaoMall = ()=>{
  window.open(TAO_BAO_MALL, '_blank');
};